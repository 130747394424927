import React from 'react';
import DefaultLayout from '../../layouts/DefaultLayout';
import TemplateColumn, { TemplateColumnWithSidebarContainer } from '../../layouts/TemplateColumn';
import { PageHeader, PageSection, PageSubSection, RelatedContent, CrossLink, IndicationPageIntro, AnchorLinks, PrimaryClaim, ComparisonTable, ReferencesBlock, KMCurve} from '../../components';
import NSCLC_2L_MONO from '../../components/StudyDesign/__study-design/nsclc-2l-mono'
import { jobCodes } from '../../util/data/jobCodes';

// SEO INFORMATION
const pageMeta = {
    title: `KEYNOTE-010 - Clinical Trial Results | HCP`,
    keywords: `keynote-010`,
    description: `Health care professionals may find clinical trial results for second-line or greater monotherapy with KEYTRUDA® (pembrolizumab) for mNSCLC in KEYNOTE-010.`,
    schemaJsonLD: [
        `{"@context":"https://schema.org/","url":"https://www.keytrudahcp.com/efficacy/nsclc-second-line-monotherapy/","@type":"ApprovedIndication","name":" KEYTRUDA®","alternateName":"pembrolizumab","description":"KEYTRUDA, as a single agent, is indicated for the treatment of patients with metastatic non⁠–⁠small cell lung cancer (NSCLC) whose tumors express programmed death ligand 1 (PD⁠-⁠L1) [tumor proportion score (TPS) ≥1%] as determined by an FDA-approved test, with disease progression on or after platinum-containing chemotherapy. Patients with epidermal growth factor receptor (EGFR) or anaplastic lymphoma kinase (ALK) genomic tumor aberrations should have disease progression on FDA-approved therapy for these aberrations prior to receiving KEYTRUDA. "}`,
        `{"@context":"https://schema.org/","url":"https://www.keytrudahcp.com/efficacy/nsclc-second-line-monotherapy/","@type":"MedicalAudience","audienceType":"US Health Care Professionals","geographicArea":"This site is intended for health care professionals of the United States, its territories, and Puerto Rico. "}`,
        `{"@context":"https://schema.org/","url":"https://www.keytrudahcp.com/efficacy/nsclc-second-line-monotherapy/","@type":"MedicalTrial","name":"Clinical Findings From KEYNOTE⁠-⁠010","description":"Study Design for KEYNOTE⁠-⁠010","trialDesign":"An open-label, multicenter, randomized, phase 2/3 trial of single-agent KEYTRUDA vs docetaxel in 1,033 patients with squamous or nonsquamous mNSCLC. "}`
    ]
}

//from /src/util/data/indications.js
const indicationId = 7; 
const jobCode = jobCodes[4].jobCode;

const relatedContent = [
    {
        section: 'Resources',
        link: {
            url: '/resources/mechanism-of-action/',
            label: 'Mechanism of Action'
        }
    },
    {
        section: 'Resources',
        link: {
            url: 'https://www.merckaccessprogram-keytruda.com/',
            label: 'Access & Reimbursement Support'
        }
    }
];

const crossLinkContent = {
    sections : [
        {
            heading: "Additional Clinical Data",
            items: [
                { text: '1L Combo Therapy – Nonsquamous', url: '/efficacy/nsclc-nonsquamous-first-line-combination-therapy/' },
                { text: '1L Combo Therapy – Squamous', url: '/efficacy/nsclc-squamous-first-line-combination-therapy/' },
                { text: '1L Monotherapy – Nonsquamous & Squamous', url: '/efficacy/nsclc-first-line-monotherapy/' },
                { text: 'Neoadjuvant Combo Therapy Followed by Adjuvant Monotherapy After Surgery for Resectable (Tumors ≥4 cm or Node Positive) NSCLC', url: '/efficacy/nsclc-perioperative-regimen/' },
                { text: 'Adjuvant Therapy for Stage IB (T2a ≥4 cm), Stage II, or Stage IIIA NSCLC', url: '/efficacy/nsclc-adjuvant-therapy/' },
            ]
        },
        {
            heading: "Related Information",
            items: [
                { text: 'Selected Adverse Reactions', url: '/safety/adverse-reactions/nsclc-second-line-monotherapy/' },
                { text: 'Dosing', url: '/dosing/options/' },
                { text: 'PD&#8288;-&#8288;L1 Testing & Scoring', url: '/biomarker-testing/pd-l1/' }
            ]
        },
    ],
    interestLinks : {
        heading: "You Might Be Interested In",
        items: [
            { eyebrow: 'Resources', text: 'Mechanism of Action', url: '/resources/mechanism-of-action/' },
        ],
    }
};

const anchorLinkModelData = [
    {label: 'Clinical Findings From KEYNOTE&#8288;-&#8288;010'}, 
    {label: 'Study Design for KEYNOTE&#8288;-&#8288;010'} 
]

const osSurvivalComparisonTableFootnotes = [
    {
        label: 'a.',
        text: 'Hazard ratio (KEYTRUDA compared with docetaxel) based on the stratified Cox proportional hazard model; <i>P</i>-value based on stratified log-rank test.'
    }
]

const pfsSurvivalComparisonTableFootnotes = [
    {
        label: 'b.',
        text: 'Hazard ratio (KEYTRUDA compared with docetaxel) based on the stratified Cox proportional hazard model; <i>P</i>-value based on stratified log-rank test.'
    }
]

const orrSurvivalComparisonTableFootnotes = [
    {
        label: 'c.',
        text: 'All responses were partial responses.'
    }
]

const osSurvivalTPS50comparisonTableFootnotes = [
    {
        label: 'd.',
        text: 'Hazard ratio (KEYTRUDA compared with docetaxel) based on the stratified Cox proportional hazard model; <i>P</i>-value based on stratified log-rank test.'
    }
]

const pfsSurvivalTPS50comparisonTableFootnotes = [
    {
        label: 'e.',
        text: 'Hazard ratio (KEYTRUDA compared with docetaxel) based on the stratified Cox proportional hazard model; <i>P</i>-value based on stratified log-rank test.'
    }
]

const orrSurvivalTPS50comparisonTableFootnotes = [
    {
        label: 'f.',
        text: 'All responses were partial responses.'
    }
]
const pageReferences = [
    {
        label: "1.",
        text: "Herbst RS, Baas P, Kim D-W, et al. Pembrolizumab versus docetaxel for previously treated, PD&#8288;-&#8288;L1-positive, advanced non-small-cell lung cancer (KEYNOTE&#8288;-&#8288;010): a randomised controlled trial. <i>Lancet.</i> 2016;387(10027):1540–1550."
    }
]

const Page = ({ location }) => {

    return (
        <DefaultLayout indicationId={indicationId} jobCode={jobCode} pageMeta={ pageMeta } location={ location }>
            <div data-template-name="template-e">

                {/* Begin Intro Column */}
                <TemplateColumn id="template-e-intro" maxWidth={824}>
                    <PageHeader title="Clinical Trial Results" />
                   <PageSection bgColor="white">
                        <IndicationPageIntro indicationId={indicationId}/>
                        <AnchorLinks items={anchorLinkModelData} />
                   </PageSection>
                   <PageSection bgColor="gradient">
                        <PrimaryClaim theme="light">Superior Overall Survival With KEYTRUDA vs Docetaxel in Second-line+ Treatment of mNSCLC With PD&#8288;-&#8288;L1 Expression (TPS&nbsp;≥1%)</PrimaryClaim>
                    </PageSection>
                </TemplateColumn>
                {/* End Intro Column */}

                <TemplateColumnWithSidebarContainer>
                {/* Begin Main Column */}
                    <TemplateColumn id="template-e-main">                      

                        <PageSection bgColor="cloud" title={anchorLinkModelData[0].label}> 
                            <PageSubSection title="Efficacy Results of All Randomized Patients With TPS&nbsp;≥1% in KEYNOTE&#8288;-&#8288;010">
                            <ComparisonTable 
                                    title="Overall Survival (OS)" 
                                    footnotes={osSurvivalComparisonTableFootnotes} 
                                    definitions="CI = confidence interval; mNSCLC = metastatic non–small cell lung cancer; HR = hazard ratio."
                                    data={
                                    [
                                        [
                                            {
                                                legendTitle: 'KEYTRUDA 2&nbsp;mg/kg Q3W (n=344)',
                                            },
                                            {
                                                legendTitle: 'KEYTRUDA 10&nbsp;mg/kg Q3W (n=346)',
                                            },
                                            {
                                                legendTitle: 'Docetaxel 75&nbsp;mg/m<sup>2</sup> Q3W (n=343)',
                                            },
                                        ],
                                        [
                                            {
                                                hrCiP: 'HR=0.71 (95% CI, 0.58–0.88; <i>P</i><0.001)<sup>a</sup>',
                                            },
                                            {
                                                hrCiP: 'HR=0.61 (95% CI, 0.49–0.75; <i>P</i><0.001)<sup>a</sup>',
                                            },
                                            {
                                                hrCiP: '—',
                                            },
                                        ],
                                        [
                                            {
                                                label: 'Deaths',
                                                dataPoint: '172',
                                                dataSubPoint: '(50%)'
                                            },
                                            {
                                                label: 'Deaths',
                                                dataPoint: '156',
                                                dataSubPoint: '(45%)'
                                            },
                                            {
                                                label: 'Deaths',
                                                dataPoint: '193',
                                                dataSubPoint: '(56%)'
                                            }
                                        ],
                                        [
                                            {
                                                label: 'Median OS',
                                                dataPoint: '10.4 months',
                                                dataSubPoint: '(95% CI, 9.4–11.9)'
                                            },
                                            {
                                                label: 'Median OS',
                                                dataPoint: '12.7 months',
                                                dataSubPoint: '(95% CI, 10.0–17.3)'
                                            },
                                            {
                                                label: 'Median OS',
                                                dataPoint: '8.5 months',
                                                dataSubPoint: '(95% CI, 7.5–9.8)'
                                            },
                                        ],
                                    ]
                                }/>

                                <ComparisonTable 
                                    title="Progression-Free Survival (PFS)" 
                                    footnotes={pfsSurvivalComparisonTableFootnotes} 
                                    definitions="Q3W = every 3 weeks."
                                    data={
                                    [
                                        [
                                            {
                                                legendTitle: 'KEYTRUDA 2&nbsp;mg/kg Q3W (n=344)',
                                            },
                                            {
                                                legendTitle: 'KEYTRUDA 10&nbsp;mg/kg Q3W (n=346)',
                                            },
                                            {
                                                legendTitle: 'Docetaxel 75&nbsp;mg/m<sup>2</sup> Q3W (n=343)',
                                            },
                                        ],
                                        [
                                            {
                                                hrCiP: 'HR=0.88 (95% CI, 0.73–1.04; <i>P</i>=0.068)<sup>b</sup>',
                                            },
                                            {
                                                hrCiP: 'HR=0.79 (95% CI, 0.66–0.94; <i>P</i>=0.005)<sup>b</sup>',
                                            },
                                            {
                                                hrCiP: '—',
                                            },
                                        ],
                                        [
                                            {
                                                label: 'Events Observed',
                                                dataPoint: '266',
                                                dataSubPoint: '(77%)'
                                            },
                                            {
                                                label: 'Events Observed',
                                                dataPoint: '255',
                                                dataSubPoint: '(74%)'
                                            },
                                            {
                                                label: 'Events Observed',
                                                dataPoint: '257',
                                                dataSubPoint: '(75%)'
                                            }
                                        ],
                                        [
                                            {
                                                label: 'Median PFS',
                                                dataPoint: '3.9 months',
                                                dataSubPoint: '(95% CI, 3.1–4.1)'
                                            },
                                            {
                                                label: 'Median PFS',
                                                dataPoint: '4.0 months',
                                                dataSubPoint: '(95% CI, 2.6–4.3)'
                                            },
                                            {
                                                label: 'Median PFS',
                                                dataPoint: '4.0 months',
                                                dataSubPoint: '(95% CI, 3.1–4.2)'
                                            },
                                        ],
                                    ]
                                }/>


                                <ComparisonTable 
                                    title="Objective Response Rate (ORR)" 
                                    footnotes={orrSurvivalComparisonTableFootnotes} 
                                    definitions="DOR = duration of response."
                                    data={
                                    [
                                        [
                                            {
                                                legendTitle: 'KEYTRUDA 2&nbsp;mg/kg Q3W (n=344)',
                                            },
                                            {
                                                legendTitle: 'KEYTRUDA 10&nbsp;mg/kg Q3W (n=346)',
                                            },
                                            {
                                                legendTitle: 'Docetaxel 75&nbsp;mg/m<sup>2</sup> Q3W (n=343)',
                                            },
                                        ],
                                        [
                                            {
                                                label: 'ORR<sup>c</sup>',
                                                dataPoint: '18%',
                                                dataSubPoint: '(95% Cl, 14–23)'
                                            },
                                            {
                                                label: 'ORR<sup>c</sup>',
                                                dataPoint: '19%',
                                                dataSubPoint: '(95% Cl, 15–23)'
                                            },
                                            {
                                                label: 'ORR<sup>c</sup>',
                                                dataPoint: '9%',
                                                dataSubPoint: '(95% Cl, 7–13)'
                                            }
                                        ],
                                        [
                                            {
                                                label: '<i>P</i> Value',
                                                dataPoint: '<0.001',
                                                dataSubPoint: '(Miettinen-Nurminen)'
                                            },
                                            {
                                                label: '<i>P</i> Value',
                                                dataPoint: '<0.001',
                                                dataSubPoint: '(Miettinen-Nurminen)'
                                            },
                                            {
                                                label: '<i>P</i> Value',
                                                dataPoint: '—'
                                            },
                                        ],
                                        [
                                            {
                                                label: 'Median DOR',
                                                dataPoint: 'Not reached',
                                                dataSubPoint: '(range: 0.7+ – 20.1+)'
                                            },
                                            {
                                                label: 'Median DOR',
                                                dataPoint: 'Not reached',
                                                dataSubPoint: '(range: 2.1+ – 17.8+)'
                                            },
                                            {
                                                label: 'Median DOR',
                                                dataPoint: '6.2 months',
                                                dataSubPoint: '(range: 1.4+ – 8.8+)'
                                            }
                                        ]
                                    ]
                                }/>

                            </PageSubSection>

                            <PageSubSection title="Efficacy Results of the Subgroup of Patients With TPS&nbsp;≥50% in KEYNOTE&#8288;-&#8288;010">
                                <ComparisonTable 
                                    title="Overall Survival (OS)" 
                                    footnotes={osSurvivalTPS50comparisonTableFootnotes} 
                                    definitions="HR = hazard ratio; CI = confidence interval; NR = not reached."
                                    data={
                                    [
                                        [
                                            {
                                                legendTitle: 'KEYTRUDA 2&nbsp;mg/kg Q3W (n=139)',
                                            },
                                            {
                                                legendTitle: 'KEYTRUDA 10&nbsp;mg/kg Q3W (n=151)',
                                            },
                                            {
                                                legendTitle: 'Docetaxel 75&nbsp;mg/m<sup>2</sup> Q3W (n=152)',
                                            },
                                        ],
                                        [
                                            {
                                                hrCiP: 'HR=0.54 (95% CI, 0.38–0.77; <i>P</i><0.001)<sup>d</sup>',
                                            },
                                            {
                                                hrCiP: 'HR=0.50 (95% CI, 0.36–0.70; <i>P</i><0.001)<sup>d</sup>',
                                            },
                                            {
                                                hrCiP: '—',
                                            },
                                        ],
                                        [
                                            {
                                                label: 'Deaths',
                                                dataPoint: '58',
                                                dataSubPoint: '(42%)'
                                            },
                                            {
                                                label: 'Deaths',
                                                dataPoint: '60',
                                                dataSubPoint: '(40%)'
                                            },
                                            {
                                                label: 'Deaths',
                                                dataPoint: '86',
                                                dataSubPoint: '(57%)'
                                            }
                                        ],
                                        [
                                            {
                                                label: 'Median OS',
                                                dataPoint: '14.9 months',
                                                dataSubPoint: '(95% CI, 10.4–NR)'
                                            },
                                            {
                                                label: 'Median OS',
                                                dataPoint: '17.3 months',
                                                dataSubPoint: '(95% CI, 11.8–NR)'
                                            },
                                            {
                                                label: 'Median OS',
                                                dataPoint: '8.2 months',
                                                dataSubPoint: '(95% CI, 6.4–10.7)'
                                            },
                                        ],
                                    ]
                                }/>

                                <ComparisonTable 
                                    title="Progression-Free Survival (PFS)" 
                                    footnotes={pfsSurvivalTPS50comparisonTableFootnotes} 
                                    data={
                                    [
                                        [
                                            {
                                                legendTitle: 'KEYTRUDA 2&nbsp;mg/kg Q3W (n=139)',
                                            },
                                            {
                                                legendTitle: 'KEYTRUDA 10&nbsp;mg/kg Q3W (n=151)',
                                            },
                                            {
                                                legendTitle: 'Docetaxel 75&nbsp;mg/m<sup>2</sup> Q3W (n=152)',
                                            },
                                        ],
                                        [
                                            {
                                                hrCiP: 'HR=0.58 (95% CI, 0.43–0.77; <i>P</i><0.001)<sup>e</sup>',
                                            },
                                            {
                                                hrCiP: 'HR=0.59 (95% CI, 0.45–0.78; <i>P</i><0.001)<sup>e</sup>',
                                            },
                                            {
                                                hrCiP: '—',
                                            },
                                        ],
                                        [
                                            {
                                                label: 'Events Observed',
                                                dataPoint: '89',
                                                dataSubPoint: '(64%)'
                                            },
                                            {
                                                label: 'Events Observed',
                                                dataPoint: '97',
                                                dataSubPoint: '(64%)'
                                            },
                                            {
                                                label: 'Events Observed',
                                                dataPoint: '118',
                                                dataSubPoint: '(78%)'
                                            }
                                        ],
                                        [
                                            {
                                                label: 'Median PFS',
                                                dataPoint: '5.2 months',
                                                dataSubPoint: '(95% CI, 4.0–6.5)'
                                            },
                                            {
                                                label: 'Median PFS',
                                                dataPoint: '5.2 months',
                                                dataSubPoint: '(95% CI, 4.1–8.1)'
                                            },
                                            {
                                                label: 'Median PFS',
                                                dataPoint: '4.1 months',
                                                dataSubPoint: '(95% CI, 3.6–4.3)'
                                            },
                                        ],
                                    ]
                                }/>

                                <ComparisonTable 
                                    title="Objective Response Rate (ORR)" 
                                    footnotes={orrSurvivalTPS50comparisonTableFootnotes} 
                                    data={
                                    [
                                        [
                                            {
                                                legendTitle: 'KEYTRUDA 2&nbsp;mg/kg Q3W (n=139)',
                                            },
                                            {
                                                legendTitle: 'KEYTRUDA 10&nbsp;mg/kg Q3W (n=151)',
                                            },
                                            {
                                                legendTitle: 'Docetaxel 75&nbsp;mg/m<sup>2</sup> Q3W (n=152)',
                                            },
                                        ],
                                        [
                                            {
                                                label: 'ORR<sup>f</sup>',
                                                dataPoint: '30%',
                                                dataSubPoint: '(95% Cl, 23–39)'
                                            },
                                            {
                                                label: 'ORR<sup>f</sup>',
                                                dataPoint: '29%',
                                                dataSubPoint: '(95% CI, 22–37)'
                                            },
                                            {
                                                label: 'ORR<sup>f</sup>',
                                                dataPoint: '8%',
                                                dataSubPoint: '(95% Cl, 4–13)'
                                            }
                                        ],
                                        [
                                            {
                                                label: '<i>P</i> Value',
                                                dataPoint: '<0.001',
                                                dataSubPoint: '(Miettinen-Nurminen)'
                                            },
                                            {
                                                label: '<i>P</i> Value',
                                                dataPoint: '<0.001',
                                                dataSubPoint: '(Miettinen-Nurminen)'
                                            },
                                            {
                                                label: '<i>P</i> Value',
                                                dataPoint: '—'
                                            },
                                        ],
                                        [
                                            {
                                                label: 'Median DOR',
                                                dataPoint: 'Not reached',
                                                dataSubPoint: '(range: 0.7+ – 16.8+)'
                                            },
                                            {
                                                label: 'Median DOR',
                                                dataPoint: 'Not reached',
                                                dataSubPoint: '(range: 2.1+ – 17.8+)'
                                            },
                                            {
                                                label: 'Median DOR',
                                                dataPoint: '8.1 months',
                                                dataSubPoint: '(range: 2.1+ – 8.8+)'
                                            }
                                        ]
                                    ]
                                }/>
                            </PageSubSection>

                            <PageSubSection title={anchorLinkModelData[1].label}>
                                <NSCLC_2L_MONO />
                            </PageSubSection>
                        </PageSection>
                    </TemplateColumn>

                    {/* End Main Column */}
                    <TemplateColumn id="template-e-sidebar">
                        <PageSection bgColor="mist">
                            <CrossLink {...crossLinkContent} />
                        </PageSection>
                    </TemplateColumn>

                </TemplateColumnWithSidebarContainer>
                
                <TemplateColumn id="template-e-secondary">
                    <PageSection bgColor="keytrudaGreen">
                        <RelatedContent items={relatedContent}/>
                    </PageSection>
                    <PageSection bgColor="mist">
                        <ReferencesBlock references={pageReferences} />
                    </PageSection>
                </TemplateColumn>
            </div>
        </DefaultLayout>
    );
};

export default Page;
